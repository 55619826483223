import React from 'react';
import { PriceList, Seo } from '@app/components';
import { Footer, Header } from '@app/components/template';
import blogBack from '@app/images/blog-back.jpg';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';

export default () => {
  const intl = useIntl();

  return (
    <>
      <Seo title={ intl.formatMessage({id: 'seo_title_prices'}) }
           description={ intl.formatMessage({id: 'seo_description_first'}) } />

      <div id="main" className="main-content-wraper">
        <div className="main-content-inner">
          <Header />

          <section className="breadcrumb-section"
                   style={ {
                     backgroundImage: `url(${ blogBack })`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center top',
                     backgroundRepeat: 'no-repeat'
                   } }>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center breadcrumb-content">
                    <h2>
                      <FormattedMessage id="footer_prices" />
                    </h2>
                    <ul className="breadcrumb">
                      <li>
                        <Link to='/'>
                          <FormattedMessage id="footer_home" />
                        </Link>
                      </li>
                      <li>
                        <FormattedMessage id="footer_prices" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="container">
            <PriceList />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}